import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  googleAuthApiRef,
  fetchApiRef
} from '@backstage/core-plugin-api';

import {
  GCalendarApiClient,
  gcalendarApiRef,
} from '@backstage/plugin-gcalendar';
import { gitlabAuthApiRef } from '@backstage/core-plugin-api';

import { cicdStatisticsApiRef } from '@backstage/plugin-cicd-statistics';
import { CicdStatisticsApiGitlab } from '@backstage/plugin-cicd-statistics-module-gitlab';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: gcalendarApiRef,
    deps: { authApi: googleAuthApiRef, fetchApi: fetchApiRef },
    factory: deps => new GCalendarApiClient(deps),
  }),
  createApiFactory({
    api: cicdStatisticsApiRef,
    deps: { gitlabAuthApi: gitlabAuthApiRef },
    factory({ gitlabAuthApi }) {
      return new CicdStatisticsApiGitlab(gitlabAuthApi);
    },
  }),
  ScmAuth.createDefaultApiFactory(),
];
