import {createApiFactory, createPlugin, createRoutableExtension, discoveryApiRef} from '@backstage/core-plugin-api';

import {rootRouteRef} from './routes';
import {corsPrometheusPimcoreApiRef} from "./api/types";
import {PromPimcoreApiClient} from "./api/api";

export const corsPrometheusPimcorePlugin = createPlugin({
    id: 'cors-prometheus-pimcore',
    apis: [
        createApiFactory({
            api: corsPrometheusPimcoreApiRef,
            deps: {
                discoveryApi: discoveryApiRef,
            },
            factory: ({discoveryApi}) =>
                new PromPimcoreApiClient({discoveryApi}),
        }),
    ],
    routes: {
        root: rootRouteRef,
    },
});

export const CorsPimcoreInstallationsPage = corsPrometheusPimcorePlugin.provide(
    createRoutableExtension({
        name: 'CorsPimcorePage',
        component: () =>
            import('./components/ProjectsPage').then(m => m.ProjectsPage),
        mountPoint: rootRouteRef,
    }),
);
