import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  DiscoveryApi,
  discoveryApiRef,
  IdentityApi,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { GCSClient, GCSApiRef } from './api';

import { rootRouteRef } from './routes';

export const gcsViewerPlugin = createPlugin({
  id: 'GCS-viewer',
  apis: [
    createApiFactory({
      api: GCSApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: (deps: {
        discoveryApi: DiscoveryApi;
        identityApi: IdentityApi;
      }) => new GCSClient(deps),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const GCSViewerPage = gcsViewerPlugin.provide(
  createComponentExtension({
    name: 'GCSViewerPage',
    component: {
      lazy: () => import('./components/Router').then(m => m.Router),
    },
  }),
);
