import { createApiRef } from '@backstage/core-plugin-api';

type SecurityAdvisory = {
    advisoryId: string;
    packageName: string;
    remoteId: string;
    title: string;
    link: string;
    cve: string;
    affectedVersions: string;
    source: string;
}

export type Project = {
    project: string;
    namespace: string;
    version: string;
    env: string;
    newest: string;
    is_out_of_date: boolean;
    recent_update: boolean;
    security_advisory: SecurityAdvisory[];

    has_security_advisory: boolean;
};

export type Bundle = {
    project: string;
    package_name: string;
    namespace: string;
    version: string;
    env: string;
    newest: string;
    is_out_of_date: boolean;
    recent_update: boolean;
    security_advisory: SecurityAdvisory[];

    has_security_advisory: boolean;
};

export interface PromPimcoreApi {
    getPimcoreProjects(): Promise<Project[]>;
    getCoreShopProjects(): Promise<Project[]>;

    getBundles(): Promise<Bundle[]>;
}

export const corsPrometheusPimcoreApiRef = createApiRef<PromPimcoreApi>({
    id: 'plugin.cors-prometheus-pimcore.service',
});