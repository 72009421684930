/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { corsPhpComposerApiRef, ComposerClient } from './api';
import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

/**
 * The Todo plugin instance.
 *
 * @public
 */
export const composerPlugin = createPlugin({
  id: 'cors-php-composer',
  apis: [
    createApiFactory({
      api: corsPhpComposerApiRef,
      deps: {
        identityApi: identityApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory({ identityApi, discoveryApi }) {
        return new ComposerClient({ identityApi, discoveryApi });
      },
    }),
  ],
  routes: {
    entityContent: rootRouteRef,
  },
});

/**
 * An extension for displaying the list of todos on an entity page.
 *
 * @public
 */
export const EntityComposerContent = composerPlugin.provide(
  createRoutableExtension({
    name: 'EntityComposerContent',
    component: () => import('./components/Composer').then(m => m.Composer),
    mountPoint: rootRouteRef,
  }),
);
