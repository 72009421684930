import {PromPimcoreApi, Project, Bundle} from './types';
import { DiscoveryApi } from '@backstage/core-plugin-api';

export class PromPimcoreApiClient implements PromPimcoreApi {
    private readonly discoveryApi: DiscoveryApi;
    constructor(options: {
        discoveryApi: DiscoveryApi;
    }) {
        this.discoveryApi = options.discoveryApi;
    }
    private async handleResponse(response: Response): Promise<any> {
        if (!response.ok) {
            throw new Error();
        }
        return await response.json();
    }
    async getPimcoreProjects(): Promise<Project[]> {
        const url = `${await this.discoveryApi.getBaseUrl('cors-prometheus-pimcore')}/pimcore`;

        const response = await fetch(url, {
            method: 'GET',
        });
        return await this.handleResponse(response);
    }
    async getCoreShopProjects(): Promise<Project[]> {
        const url = `${await this.discoveryApi.getBaseUrl('cors-prometheus-pimcore')}/coreshop`;

        const response = await fetch(url, {
            method: 'GET',
        });
        return await this.handleResponse(response);
    }
    async getBundles(): Promise<Bundle[]> {
        const url = `${await this.discoveryApi.getBaseUrl('cors-prometheus-pimcore')}/bundles`;

        const response = await fetch(url, {
            method: 'GET',
        });
        return await this.handleResponse(response);
    }
}