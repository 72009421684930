import {
    // DomainExplorerContent,
    ExploreLayout,
    ToolExplorerContent
} from '@backstage/plugin-explore';
import React from 'react';

export const ExplorePage = () => {
    return (
        <ExploreLayout
            title={`Explore the CORS ecosystem`}
            subtitle="Our Tools for the daily business"
        >
            {/*<ExploreLayout.Route path="domains" title="Domains">*/}
            {/*    <DomainExplorerContent />*/}
            {/*</ExploreLayout.Route>*/}
            {/*<ExploreLayout.Route path="groups" title="Groups">*/}
            {/*    <GroupsExplorerContent />*/}
            {/*</ExploreLayout.Route>*/}
            <ExploreLayout.Route path="tools" title="Tools">
                <ToolExplorerContent />
            </ExploreLayout.Route>
        </ExploreLayout>
    );
};

export const explorePage = <ExplorePage />;