import React from 'react';
import Icon from '@material-ui/core/Icon';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    svg: {
        width: 20,
        height: 'auto',
    },
    path: {
        fill: 'white',
    },
});
const PimcoreIcon = () => {
    const classes = useStyles();

    return (
        <Icon>
            <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1307.96 762.97">
                <path className={classes.path} d="m1035.46,544.99c90.15,0,163.51-73.36,163.51-163.51s-73.36-163.51-163.51-163.51c-56.74,0-106.85,29.1-136.16,73.14l-59.98,90.36,59.51,89.72c29.27,44.43,79.6,73.78,136.63,73.78m0-436c150.47,0,272.49,122.02,272.49,272.5s-122.02,272.5-272.49,272.5c-94.85,0-178.37-48.45-227.17-121.98l-34.44-51.82-74.17,111.85c-68.32,103.01-185.34,170.94-318.21,170.94C170.81,762.97,0,592.2,0,381.48S170.81,0,381.48,0c132.32,0,248.87,67.33,317.31,169.62l9.65,14.57-65.45,98.74-35.8-53.96c-49.05-72.33-131.93-119.97-225.71-119.97-150.26,0-272.5,122.23-272.5,272.5s122.23,272.5,272.5,272.5c94.72,0,178.25-48.53,227.12-122.06l199.48-300.65c48.79-73.7,132.4-122.28,227.38-122.28"/>
            </svg>
        </Icon>
    );
}
export default PimcoreIcon;