import React from 'react';
import {Route} from 'react-router-dom';
import {apiDocsPlugin, ApiExplorerPage} from '@backstage/plugin-api-docs';
import {CatalogEntityPage, CatalogIndexPage, catalogPlugin,} from '@backstage/plugin-catalog';
import {CatalogImportPage, catalogImportPlugin,} from '@backstage/plugin-catalog-import';
import {ScaffolderPage, scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {orgPlugin} from '@backstage/plugin-org';
import {SearchPage} from '@backstage/plugin-search';
import {TechRadarPage} from '@backstage/plugin-tech-radar';
import {TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage,} from '@backstage/plugin-techdocs';
import {TechDocsAddons} from '@backstage/plugin-techdocs-react';
import {ReportIssue} from '@backstage/plugin-techdocs-module-addons-contrib';
import {UserSettingsPage} from '@backstage/plugin-user-settings';
import {apis} from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {searchPage} from './components/search/SearchPage';
import {Root} from './components/Root';

import {AlertDisplay, OAuthRequestDialog, SignInPage} from '@backstage/core-components';
import {createApp} from '@backstage/app-defaults';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {CatalogGraphPage} from '@backstage/plugin-catalog-graph';
import {RequirePermission} from '@backstage/plugin-permission-react';
import {catalogEntityCreatePermission} from '@backstage/plugin-catalog-common/alpha';
import {configApiRef, googleAuthApiRef, useApi} from "@backstage/core-plugin-api";
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import {LighthousePage, lighthousePlugin} from '@backstage/plugin-lighthouse';
import {stackOverflowPlugin} from '@backstage/plugin-stack-overflow';
import { shortcutsPlugin } from '@backstage/plugin-shortcuts';
import { ExplorePage } from '@backstage/plugin-explore';
import { explorePage } from './components/explore/ExplorePage';
import { ToolboxPage } from '@drodil/backstage-plugin-toolbox';
import { QetaPage } from '@drodil/backstage-plugin-qeta';
import {CorsPimcoreInstallationsPage} from '@cors-gmbh/plugin-prometheus-pimcore';
import { GCSViewerPage } from '@cors-gmbh/plugin-gcs-viewer';

const app = createApp({
    apis,
    plugins: [
        lighthousePlugin,
        shortcutsPlugin,
        stackOverflowPlugin
    ],
    bindRoutes({bind}) {
        bind(catalogPlugin.externalRoutes, {
            createComponent: scaffolderPlugin.routes.root,
            viewTechDoc: techdocsPlugin.routes.docRoot,
        });
        bind(apiDocsPlugin.externalRoutes, {
            registerApi: catalogImportPlugin.routes.importPage,
        });
        bind(scaffolderPlugin.externalRoutes, {
            registerComponent: catalogImportPlugin.routes.importPage,
        });
        bind(orgPlugin.externalRoutes, {
            catalogIndex: catalogPlugin.routes.catalogIndex,
        });
    },
    components: {
        SignInPage: props => {
            const configApi = useApi(configApiRef);
            const providers: any = [{
                id: 'google-auth-provider',
                title: 'Google',
                message: 'Sign in using Google',
                apiRef: googleAuthApiRef,
            }];

            if (configApi.getString('auth.environment') === 'development') {
                providers.push('guest');
            }

            return (
                <SignInPage
                    {...props}
                    providers={providers}
                    align="center"
                />
            );
        },
    },
});

const routes = (
    <FlatRoutes>
        <Route path="/" element={<HomepageCompositionRoot />}>
            <HomePage />
        </Route>
        <Route path="/lighthouse" element={<LighthousePage />} />
        <Route path="/catalog" element={<CatalogIndexPage/>}/>
        <Route
            path="/catalog/:namespace/:kind/:name"
            element={<CatalogEntityPage/>}
        >
            {entityPage}
        </Route>
        <Route path="/docs" element={<TechDocsIndexPage/>}/>
        <Route
            path="/docs/:namespace/:kind/:name/*"
            element={<TechDocsReaderPage/>}
        >
            <TechDocsAddons>
                <ReportIssue/>
            </TechDocsAddons>
        </Route>
        <Route path="/create" element={<ScaffolderPage/>}/>
        <Route path="/api-docs" element={<ApiExplorerPage/>}/>
        <Route
            path="/tech-radar"
            element={<TechRadarPage width={1500} height={800}/>}
        />
        <Route
            path="/catalog-import"
            element={
                <RequirePermission permission={catalogEntityCreatePermission}>
                    <CatalogImportPage/>
                </RequirePermission>
            }
        />
        <Route path="/search" element={<SearchPage/>}>
            {searchPage}
        </Route>
        <Route path="/settings" element={<UserSettingsPage/>}/>
        <Route path="/catalog-graph" element={<CatalogGraphPage/>}/>
        <Route path="/explore" element={<ExplorePage />}>
            {explorePage}
        </Route>
        <Route path="/pimcore" element={<CorsPimcoreInstallationsPage />} />
        <Route path="/gcs-viewer" element={<GCSViewerPage />} />
        <Route path="/toolbox" element={<ToolboxPage />} />
        <Route path="/qeta" element={<QetaPage title="Questions" />} />
    </FlatRoutes>
);

export default app.createRoot(
    <>
        <AlertDisplay/>
        <OAuthRequestDialog/>
        <AppRouter>
            <Root>{routes}</Root>
        </AppRouter>
    </>,
);
