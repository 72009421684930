import {Header, Page, RoutedTabs} from '@backstage/core-components';
import {default as React} from 'react';
import {attachComponentData, useElementFilter} from "@backstage/core-plugin-api";
import {TabProps} from "@material-ui/core";

export type CorsProjectsLayoutProps = {
    title?: string;
    subtitle?: string;
    children?: React.ReactNode;
};

const dataKey = 'plugin.explore.corsProjects';

const Route: (props: SubRoute) => null = () => null;
attachComponentData(Route, dataKey, true);

// This causes all mount points that are discovered within this route to use the path of the route itself
attachComponentData(Route, 'core.gatherMountPoints', true);

export type SubRoute = {
    path: string;
    title: string;
    children: JSX.Element;
    tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
};

export const ProjectsLayout = (props: CorsProjectsLayoutProps) => {
    const { children } = props;

    const routes = useElementFilter(children, elements =>
        elements
            .getElements<SubRoute>()
            .map(child => child.props),
    );

    return (
        <Page themeId="home">
            <Header
                title='Pimcore Installations'
            />
            <RoutedTabs routes={routes} />
        </Page>
    );
};


ProjectsLayout.Route = Route;