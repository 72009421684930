/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { stringifyEntityRef } from '@backstage/catalog-model';
import { ResponseError } from '@backstage/errors';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import {ComposerApi, ComposerGetOptions, ComposerGetResult} from "./types";

/**
 * Options for creating a todo client.
 *
 * @public
 */
export interface ComposerClientOptions {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
}

/**
 * An implementation of the TodoApi that talks to the todo plugin backend.
 *
 * @public
 */
export class ComposerClient implements ComposerApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: ComposerClientOptions) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async getComposer(options: ComposerGetOptions): Promise<ComposerGetResult> {
    const { entity} = options;
    const baseUrl = await this.discoveryApi.getBaseUrl('cors-php-composer');
    const { token } = await this.identityApi.getCredentials();

    const query = new URLSearchParams();
    if (entity) {
      query.set('entity', stringifyEntityRef(entity));
    }

    const res = await fetch(`${baseUrl}/v1/composer?${query}`, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
    });

    if (!res.ok) {
      throw await ResponseError.fromResponse(res);
    }

    const data: ComposerGetResult = await res.json();
    return data;
  }
}
