import {createPlugin, createRoutableExtension, createRouteRef} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const rootCatalogKubernetesCostRouteRef = createRouteRef({
    id: 'kubernetes-cost',
});
export const corsKubernetesCostPlugin = createPlugin({
  id: 'cors-kubernetes-cost',
  routes: {
    root: rootRouteRef,
  },
});

export const EntityKubernetesCostContent: (
    props: any,
) => JSX.Element = corsKubernetesCostPlugin.provide(
    createRoutableExtension({
        name: 'EntityCorsKubernetesCostPage',
        component: () => import('./Router').then(m => m.Router),
        mountPoint: rootCatalogKubernetesCostRouteRef,
    }),
);
