/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Entity} from '@backstage/catalog-model';
import {createApiRef} from '@backstage/core-plugin-api';


export type ComposerLock = {
    _readme: string[],
    "content-hash": string,
    packages: ComposerPackage[],
}

export type ComposerPackage = {
    name: string,
    description: string,
    version: string
}

/**
 * @public
 */
export type ComposerGetOptions = {
    entity?: Entity;
};

/**
 * The result of listing todos.
 *
 * @public
 */
export type ComposerGetResult = {
    composer: ComposerLock;
    totalCount: number;
};

/**
 * The API used by the todo-plugin to list todos.
 *
 * @public
 */
export interface ComposerApi {
    /**
     * Lists todo items.
     *
     * @public
     */
    getComposer(options: ComposerGetOptions): Promise<ComposerGetResult>;
}

/**
 * ApiRef for the TodoApi.
 *
 * @public
 */
export const corsPhpComposerApiRef = createApiRef<ComposerApi>({
    id: 'plugin.todo.cors-php-composer',
});
