import React from 'react';
import Grid from '@material-ui/core/Grid'
import {HomePageRandomJoke, HomePageStarredEntities, WelcomeTitle} from "@backstage/plugin-home";
import {HomePageCalendar} from "@backstage/plugin-gcalendar";
import {HomePageStackOverflowQuestions} from "@backstage/plugin-stack-overflow";
import {Content, Header, Page} from "@backstage/core-components";

export const HomePage = () => {
    return (
        <Page themeId="home">
            <Header title="Home">
                <WelcomeTitle />
            </Header>
            <Content>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <HomePageRandomJoke/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <HomePageStarredEntities/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <HomePageCalendar/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <HomePageStackOverflowQuestions
                            requestParams={{
                                tagged: 'pimcore',
                                site: 'stackoverflow',
                                pagesize: 5,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <HomePageStackOverflowQuestions
                            requestParams={{
                                tagged: 'coreshop',
                                site: 'stackoverflow',
                                pagesize: 5,
                            }}
                        />
                    </Grid>
                </Grid>
            </Content>
        </Page>
    )
};